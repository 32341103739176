export default {
  black: '#000000',
  accent: '#F2F2F2',
  grey: {
    '10': '#151515',
    '20': '#333333',
    '30': '#666666',
    '40': '#777777',
    '50': '#AAAAAA',
    '60': '#CCCCCC',
    '70': '#DDDDDD',
    '80': '#F2F2F2',
    '90': '#F8F8F8',
  },
  transparent: 'transparent',
  white: '#FFFFFF',
}
