import preset from '@rebass/preset'
import merge from 'lodash/merge'
import colors from '../theme/colors'
import theme from '../theme'

export default merge({}, preset, {
  breakpoints: ['320px', '600px', '1028px', '1200px', '1366px'],
  colors: {
    text: colors.black,
    background: '#F4F3F1',
    primary: colors.grey[40],
  },
  fonts: {
    body: 'ProximaNova, system-ui, sans-serif',
    heading: 'ProximaNova, system-ui, sans-serif',
    monospace: 'ProximaNova, monospace',
  },
  fontSizes: [
    '12px',
    '15px',
    '16px',
    '18px',
    '20px',
    '24px',
    '30px',
    '44px',
    '72px',
  ],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  layout: {
    container: {
      maxWidth: 1028,
      px: [4, 4, 5, 5, 5],
    },
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  styles: {
    h1: {
      fontSize: [5, 5, 5, 6, 6],
      lineHeight: 1.25,
      m: 0,
      fontWeight: 'heading',
      mb: [3, 3, 3, 3, 3],
    },
    h2: {
      fontSize: [4, 4, 4, 5, 5],
      lineHeight: 1.25,
      fontWeight: 'normal',
    },
    h3: {
      fontSize: [2, 2, 2, 2, 2],
      lineHeight: 1.1,
      mb: [3, 3, 3, 3, 3],
      fontWeight: 'body',
    },
    h4: {
      lineHeight: 1,
      mt: [3, 3, 3, 3, 3],
      mb: [2, 2, 2, 2, 2],
      fontWeight: 'normal',
    },
    h6: {
      fontSize: [2, 2, 2, 2, 2],
      lineHeight: 1.3,
      fontWeight: 'body',
    },
    p: {
      fontSize: [2, 2, 2, 2, 2],
      lineHeight: 1.3,
      fontWeight: 'body',
      mb: 3,
    },
    dl: {
      fontSize: [0, 0, 0, 0, 0],
      lineHeight: 1.45,
      my: 1,
      color: theme.copyright.color,
    },
    a: {
      color: '#000',
      boxShadow: `0 -1px 0 0 ${theme.text.color} inset`,
      ':hover': {
        color: theme.text.hover,
        transition: 'all .3s ease-out',
        boxShadow: `0 -1px 0 0 ${theme.text.hover} inset`,
        width: 'fit-content',
      },
    },
    ul: {
      pl: [3, 4],
      fontSize: [2, 2, 2, 2],
      lineHeight: 1.3,
      fontWeight: 'body',
      mt: [2, 2, 2, 2, 2],
      mb: [1, 1, 1, 1, 1],
    },
    li: {
      fontSize: [2, 2, 2, 2],
      lineHeight: 1.3,
      fontWeight: 'body',
      mb: [2, 2, 2, 2, 2],
    },
  },
})
