import colors from './colors'
import { sizes } from './deviceWidths'

const breakpoints = [
  `${sizes.s}px`,
  `${sizes.m}px`,
  `${sizes.l}px`,
  `${sizes.xl}px`,
]

breakpoints.s = breakpoints[0]
breakpoints.m = breakpoints[1]
breakpoints.l = breakpoints[2]
breakpoints.xl = breakpoints[3]

const shared = {
  colors,
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  breakpoints,
}

export default shared
