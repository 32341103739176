import colors from './colors'
import shared from './shared'

const colorRoles = {
  name: 'Light',
  primary: colors.white,
  secondary: colors.grey['40'],
  tertiary: colors.grey['60'],
  other: colors.grey['50'],
  accent: colors.black,
}

export default {
  name: 'Light',

  text: {
    color: colorRoles.accent,
    hover: colorRoles.secondary,
  },

  titles: {
    backgroundColor: colorRoles.accent,
    textColor: colorRoles.primary,
  },

  intro: {
    textColor: colorRoles.secondary,
  },

  header: {
    backgroundColor: colorRoles.accent,
    logoBackgroundColor: colorRoles.primary,
  },

  nav: {
    backgroundColor: colorRoles.accent,
    navLink: colorRoles.accent,
    navSeparator: colorRoles.primary,
    linkActive: colorRoles.tertiary,
    textColor: '#e9e7e2',
  },

  link: {
    primary: {
      color: colorRoles.accent,
      ':hover': {
        color: colorRoles.tertiary,
      },
      ':active': {
        color: colorRoles.tertiary,
      },
    },
    secondary: {
      color: colorRoles.primary,
      ':hover': {
        textDecoration: 'underline',
      },
    },
  },

  reportsMenu: {
    color: colorRoles.tertiary,
    ':hover': {
      textDecoration: 'underline',
      color: colorRoles.secondary,
    },
  },

  subscribe: {
    borderColor: colorRoles.secondary,
    hoverButton: colorRoles.secondary,
  },

  copyright: {
    color: colorRoles.other,
  },

  footer: {
    backgroundColor: colorRoles.accent,
  },

  menuItemColors: [
    `#FF0018`,
    `#FFA52C`,
    `#FFFF41`,
    `#1FC600`,
    `#2A9DF4`,
    `#8A2BE2`,
    `#FF0080`,
    `#3FC1BE`,
  ],

  ...shared,
}
